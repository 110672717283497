import { Currency } from 'f1-utils'
import { appConfig } from './appConfig'
const { setParticularLocaleMethods } = Currency

const config = {
  defaults: {
    APP_BASE_TITLE: `${appConfig.appBaseTitle}`,
    DEFAULT_UNITS: `${appConfig.defaultUnits}`,
    MAPBOX_ACCESS_TOKEN: `${appConfig.mapboxAccessToken}`,
    MAPBOX_USERNAME: `${appConfig.mapboxUsername}`,
    MAPBOX_LIGHT_MAP_ID: `${appConfig.mapboxLightMapId}`,
    MAPBOX_BASIC_MAP_ID: `${appConfig.mapboxBasicMapId}`,
    ENABLE_I18N_DEBUG: appConfig.enableI18nDebug,
    GA_TRACKING_ID: `${appConfig.gaTrackingId}`,
    HEADERS: {
      'Content-Type': 'application/json; charset=utf-8',
      'X-Api-Key': 'x'
    },
    RECAPTCHA_KEY: `${appConfig.recaptchaKey}`,
    API_HOST: `${appConfig.apiHost}`,
    DEBUG_MODE: `${appConfig.debugMode}`,
    GOOGLEMAPS_API_TOKEN: `${appConfig.googlemapsApiToken}`
  }
}

const reactAppPrefix = 'REACT_APP_'

const AppConfig = {
  ...config.defaults,
  ...Object.entries(process.env)
    .filter(([env]) => env.startsWith(reactAppPrefix))
    .reduce(
      (obj, [env, value]) => ({
        ...obj,
        [env.replace(reactAppPrefix, '')]: value
      }),
      {}
    )
}

const setAppConfigCurrency = (currency) => {
  AppConfig.CURRENCYFORMAT = setParticularLocaleMethods(currency)
  AppConfig.CURRENCY = currency.abbreviation
  AppConfig.CURRENCYCONFIG = currency
}

const setAppDefaultCountry = (country) => {
  AppConfig.DEFAULT_COUNTRY = country
}

export { AppConfig, setAppConfigCurrency, setAppDefaultCountry }
