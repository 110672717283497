import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { isValid } from 'date-fns'
import moment from 'moment'
import { AppConfig } from 'environment'

require('moment/locale/es')
require('moment/locale/pl')
require('moment/locale/fr')
require('moment/locale/fr-ch')
require('moment/locale/it')
require('moment/locale/de')
require('moment/locale/de-ch')
require('moment/locale/sv')

const nbOverrides = require('./assets/locales/no-NO.json')
const enUs = require('./assets/locales/en-US.json')
const usAndNo = { ...enUs, ...nbOverrides }

const commonNS = 'translation'
const i18nResources = {
  en: { [commonNS]: enUs },
  'en-US': { [commonNS]: enUs },
  es: { [commonNS]: require('./assets/locales/es-ES.json') },
  'es-ES': { [commonNS]: require('./assets/locales/es-ES.json') },
  no: { [commonNS]: require('./assets/locales/no-NO.json') },
  'no-NO': { [commonNS]: require('./assets/locales/no-NO.json') },
  de: { [commonNS]: require('./assets/locales/de-DE.json') },
  'de-DE': { [commonNS]: require('./assets/locales/de-DE.json') },
  'de-CH': { [commonNS]: require('./assets/locales/de-CH.json') },
  fr: { [commonNS]: require('./assets/locales/fr-FR.json') },
  'fr-FR': { [commonNS]: require('./assets/locales/fr-FR.json') },
  it: { [commonNS]: require('./assets/locales/it-IT.json') },
  'it-IT': { [commonNS]: require('./assets/locales/it-IT.json') },
  nb: { [commonNS]: usAndNo },
  'nb-NO': { [commonNS]: usAndNo },
  nn: { [commonNS]: usAndNo },
  'nn-NO': { [commonNS]: usAndNo },
  pl: { [commonNS]: require('./assets/locales/pl-PL.json') },
  'pl-PL': { [commonNS]: require('./assets/locales/pl-PL.json') },
  sv: { [commonNS]: require('./assets/locales/sv-SE.json') },
  'sv-SE': { [commonNS]: require('./assets/locales/sv-SE.json') }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: i18nResources,
    debug: !!AppConfig.ENABLE_I18N_DEBUG,
    fallbackLng: {
      nb: ['no'],
      nn: ['no'],
      'nb-NO': ['no'],
      'no-NO': ['no'],
      'nn-NO': ['no'],
      default: ['en-US']
    },
    ns: [commonNS],
    defaultNS: [commonNS],
    interpolation: {
      escapeValue: false, // React already safe from xss
      format: (value, formatStr) => {
        if (isValid(new Date(value))) {
          return moment(value)
            .locale(i18n.language)
            .format(formatStr)
        }

        return value
      }
    },
    detection: { order: ['localStorage'] }
  })
  .then(() => {
    let language = (i18n.language || 'en-US').substring(0, 2)
    moment.locale(language)
  })

function getResourceFormat(instance, key) {
  let resource

  // We may not have translations for the language detected
  // so get the first resource that can be found.
  // SEE: https://www.i18next.com/overview/api#languages
  for (const lang of instance.languages) {
    const res = instance.getResource(lang, commonNS, key)
    if (res) {
      resource = res
      break
    }
  }

  const args = resource.split(',')
  if (args.length !== 2) {
    return ''
  }
  return args[1].replace('}}', '').trim()
}

export default i18n
export { getResourceFormat }
